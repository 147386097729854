import React from "react"
import { Link, graphql } from "gatsby"

import IndexBlock from "../components/indexBlock"
import { NewsPiece, NewsLink } from "../components/newsModules"
// import Image from "../components/image"
import SEO from "../components/seo"
// import ButtonLink from "../components/buttonLink"
import Sponsors from "../components/sponsors"
import SportsLogo from "../components/sportsLogo"
// import AlbumLinks from "../components/albumLinks"
// import { removePlaceholder } from "../components/helperFunctions"

export const LinkToContent = ({ linkText, linkTo, className }) => (
  <Link
    className={`rounded-lg shadow-lg font-semibold text-5xl md:text-6xl font-heading text-white hover:text-secondary px-2 py-3 h-32 flex justify-center items-center bg-gradient-to-b from-logoDarkBlue  to-logoLightBlue ${className}`}
    to={linkTo}
    style={{ textDecoration: "none" }}
    aria-label={linkText} // Add aria-label attribute
  >
    {linkText}
  </Link>
)

const IndexPage = ({ data }) => {
  // Array of data from gql query
  // const allNewsArray = data.allContentfulNews.edges
  // const allAlbumsArray = data.photoAlbums.edges

  // Define new array of data, minus placeholder content
  // const allNewsArrayFiltered = removePlaceholder(allNewsArray)
  // const allAlbumsArrayFiltered = removePlaceholder(allAlbumsArray)

  return (
    <div>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div className="index">
        {/* <HeroImages /> */}

        {/* LOGO GOES HERE */}
        {/* <div className="border grid w-full"> */}
        <SportsLogo className="w-40  md:w-64 mt-10" alt="SLMHA Logo" />
        <h1 className="font-extrabold text-4xl md:5xl lg:text-6xl xl:text-7xl text-center font-heading text-primary">
          Sioux Lookout
          <br />
          Minor Hockey Association
        </h1>
        {/* </div> */}

        {/* Section: Links to content */}

        <div className=" w-full px-5 py-8">
          {/* Container for links */}
          <div className="grid grid-flow-row lg:grid-flow-col gap-5 max-w-xl lg:max-w-none mx-auto ">
            <LinkToContent linkText="Registration" linkTo="/registration" />
            <LinkToContent linkText="Schedule" linkTo="/calendar" />
            <LinkToContent linkText="Contact Us" linkTo="/contact" />
          </div>
        </div>

        {/* News Section - disabled by request, October 31, 2023 */}

        {/* <IndexBlock blockClass="indexBlock--odd" columns="double">
          <div className="indexBlock__a">
            <h2 className="index__h2">Recent News</h2>
            {allNewsArrayFiltered
              .filter(({ node }, index) => index === 0)
              .map((edge, index) => (
                <NewsPiece key={index} node={edge.node} />
              ))}
          </div>
          <div className="indexBlock__b">
            <h2 className="index__h2">More Posts</h2>
            <div className="index__morePostsContainer">
              <ul className="index__newsList">
                {allNewsArrayFiltered
                  .filter(({ node }, index) => index !== 0 && index <= 3)
                  .map((edge, index) => (
                    <NewsLink key={index} node={edge.node} />
                  ))}
              </ul>
              <ButtonLink
                linkTo="/news"
                buttonText="View All Posts"
              ></ButtonLink>
            </div>
          </div>
        </IndexBlock> */}

        {/* Photo Album Section */}

        {/* <IndexBlock blockClass="indexBlock--even indexBlock--1col">
          <h2 className="index__h2">Photo Albums</h2>
          <div className="index__albums">
            {allAlbumsArrayFiltered.map((album, index) => (
              <div className="index__albumContainer" key={album.node.id}>
                <h2
                  className="index__albumTitle"
                  style={{ textAlign: "center" }}
                >
                  {album.node.albumTitle}
                </h2>
                <AlbumLinks album={album} />
              </div>
            ))}
          </div>
          <ButtonLink buttonText="View All Albums" linkTo="/photos" />
        </IndexBlock> */}

        {/* Sponsors Section */}

        <IndexBlock blockClass=" rounded " columns="single">
          <Sponsors></Sponsors>
        </IndexBlock>
      </div>
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query indexQuery {
    allContentfulNews(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: [date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          title
          author {
            author
          }
          slug
          content {
            raw
          }
          summary {
            summary
          }
          date(formatString: "MMMM Do, YYYY")
          placeholder
          primaryImage {
            altText
            title
            imageFile {
              fixed(width: 200) {
                ...GatsbyContentfulFixed
              }
            }
          }
        }
      }
    }
    indexImg: file(relativePath: { eq: "SLMHA_Header-cropped.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photoAlbums: allContentfulAlbum(limit: 5) {
      edges {
        node {
          id
          albumTitle
          slug
          photos {
            id
            fixed(width: 250, height: 250) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          placeholder
        }
      }
    }
  }
`
